<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2021-01-01 17:19:50
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 15:41:36
 * @FilePath: /refactor-with-vue3/src/views/error/404.vue
-->
<template>
  <div class="container">404</div>
</template>

<script>
export default {
  name: "notFoned",
  setup() {
    return {};
  }
};
</script>

<style scoped lang="scss"></style>
